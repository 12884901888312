import { Component, OnInit, Input } from '@angular/core';
import { BlogsService } from '@cms/services/blogs.service';
import { ProductsService } from '@cms/services/products.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { find, cloneDeep } from 'lodash';
@Component({
    selector: 'app-modal-blog-related',
    templateUrl: './modal-add-blog-related.component.html',
    styleUrls: ['./modal-add-blog-related.component.css']
})
export class ModalAddBlogRelatedComponent implements OnInit {
    @Input() data: any;
    message_duration: number = 4000;
    model:any = {
        "lang": "lang1",
        "status": "",
        "search": "",
        "cate_id": "",
        "page": 1,
        "perpage": 10,
        "sort": "desc"
    }
    blog:any = [];
    total:number = 0;
    selecteditem:any = [];
    current_blog_id:any = null;
    constructor(
        private modal: NzModalRef,
        private message: NzMessageService,
        private Service_Blog: BlogsService
    ) { }

    ngOnInit() {
        this.search();
        this.current_blog_id = this.data.blog_id;
        this.selecteditem = cloneDeep(this.data.blog_related);

    }

    search(){
        this.Service_Blog.searchBlog(this.model).then((res:any) => {
            console.log(res);
            this.blog = res.data;
            this.total = res.count;

        })
    }
    pagechage(page) {
        this.model.page = page;
        this.search();
    }
    deleteSelected(i) {
        this.selecteditem.splice(i, 1);
    }
    add(data) {
        let chk = find(this.selecteditem, function (o) { return o.post_id == data.post_id; });
        if (!chk) {
            this.selecteditem.push(data);
        }
    }

    ok() {
        this.modal.close(this.selecteditem);
        return false;
    }
}