import { version } from './version';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
var url_api = 'https://devapi.sandclockbooks.com'

export const environment = {
  ketshopweb: true,
  logo: '/assets/images/logoket_shop.svg',
  logo_login: '/system/core-imgs/logo_login.png',
  logo_preload: '/system/core-imgs/logo2.png',
  beta: true,
  version: version,
  google_map_api_key: 'AIzaSyCcRiv29lJEoQDx0pBU6fs0GkQzbQFA9Tc',
  thinknet_map: {
    app_id: "ketshopweb-aozrg",
    api_key: "YmY4ZDkzMTNlMWI1NzZhOGM5Nzc5MGFjNDg5MmZlMGM"
  },
  app_facebook: '438782773147074',
  production: false,
  api_path: `${url_api}/api/v1`,
  api_center: `${url_api}/center`,
  login_path: `${url_api}/login`,
  facebook_path: `${url_api}/facebook`,
  delivery_path: `${url_api}/delivery`,
  pwa_url: 'https://devpwa.ketshoptest.com',
  chats_app: 'https://uat-omni-chat.pages.dev/chats',
  hmr: false,
  public_key: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA13n3FquEFX1uUQmXYuWv
MkX09bZyuzwyZmXHsVNkSCllNnSm7yUZKHAWkhHhaWk0+h3FDqvuiWGBNkeHTppx
P+Q3fQLMLXrRkKCcKocNrNW0QKzlTWdbGi4WMLkeYhUu4xMWDdGlPyH8B0WreO9D
0BazLVfHgTFfc7oIgn1qNqe4zuqmewXS1UvisAWyqERNPa0TyFD+eQoNB/ws4q97
EQ5M73I8lJ0VposG+5BQRuW2uxXYu5Lzic1UH98wGon/RFCMxMLr/xBk6kOrWy05
SQd+xxEQPg5naTEhKILwCJBhVuQ+SY8apLoOODU1tm4XYHWSO6hta7RdQJymnlhm
9wIDAQAB
-----END PUBLIC KEY-----`,
  firebase: {
    apiKey: "AIzaSyBh9pvdwe8A8f3RzZqUmP0y0mosUqyUgfU",
    authDomain: "third-hangout-162019.firebaseapp.com",
    databaseURL: "https://third-hangout-162019.firebaseio.com",
    projectId: "third-hangout-162019",
    storageBucket: "third-hangout-162019.appspot.com",
    messagingSenderId: "368459716648",
    appId: "1:368459716648:web:b3e25cc0156c49da"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.








