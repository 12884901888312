import { Injectable } from '@angular/core';
import { Etc } from '../util/etc';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class FrontsService {

  constructor(
    private _etc:Etc,
    private _http:HttpClient,
    private _cookie:CookieService
    ) { }

  testSvc(){
    return this._http.get('').toPromise()
  }

  updateMenuFilterDetailSort(data){
    return this._http.post('/page/updateMenuFilterDetailSort', data).toPromise();
  }
  updateMenuFilterSort(data:any) {
    return this._http.post('/page/updateMenuFilterSort',data).toPromise();
  }

  getMenuFilter() {
    return this._http.get('/page/getMenuFilter').toPromise();
  }
  getMenuFilterDetail(id) {
    return this._http.get('/page/getMenuFilterDetail/'+id).toPromise();
  }

  createMenuFilter(data:any){
    return this._http.post('/page/createMenuFilter',data).toPromise();
  }

  createMenuFilterDetail(data: any) {
    return this._http.post('/page/createMenuFilterDetail', data).toPromise();
  }
  updateMenuFilterDetail(data:any){
    return this._http.post('/page/updateMenuFilterDetail', data).toPromise();
  }

  updateMenuFilter(data: any) {
    return this._http.post('/page/updateMenuFilter', data).toPromise();
  }

  deleteMenuFilterDetail(data: any) {
    return this._http.post('/page/deleteMenuFilterDetail', data).toPromise();
  }
  deleteMenuFilter(data: any) {
    return this._http.post('/page/deleteMenuFilter', data).toPromise();
  }

  getElements(){
    return this._http.get('/assets/default/elements.json').toPromise();
  }

  getPremadeSections() {
    return this._http.get('/assets/default/premade-section.json').toPromise();
  }

  getSections(){
    return this._http.get('/assets/default/sections.json').toPromise();
  }

  savePage(data:any){
    return this._http.post('/page/save', data).toPromise();
  }

  savePageByProduct(data:any){
    return this._http.post('/product/saveSectionElement', data).toPromise();
  }


  getPageEleAndSection(data:any){
    return this._http.post('/page/listsectionelement', data).toPromise();
  }

  getProductEleAndSection(data:any){
    return this._http.post('/product/productDetail', data).toPromise();
  }

  getPageEleAndSectionFront(data:any){
    return this._http.post('/page/listsectionelementFront', data).toPromise();
  }

  getHeadersList(){
    return this._http.get('/assets/default/headers.json').toPromise();
  }

  getElementForCustomLayout(){
    return this._http.get('/assets/default/custom-layout.json').toPromise();
  }

  getElementForFooter(){
    return this._http.get('/assets/default/footer-elements.json').toPromise();
  }


  getHeaders(){
    return this._http.get('/assets/default/header.json').toPromise();
  }

  addToDisplay(obj){
    return this._http.post('/page/addToDisplay', obj).toPromise();
  }
  addToPageList(obj){
    return this._http.post('/page/addToPageList', obj).toPromise();
  }
  updateMenuEnable(obj){
    return this._http.post('/page/updateMenuEnable', obj).toPromise();
  }
  updateMenuName(obj) {
    return this._http.post('/page/updateMenuName', obj).toPromise();
  }
  createMenu(obj) {
    return this._http.post('/page/menu', obj).toPromise();
  }
  deleteMenu(obj){
    return this._http.delete('/page/menu/'+obj.page_id).toPromise();
  }
  updateMenuSort(obj){
    return this._http.post('/page/updateMenuSort',obj).toPromise();
  }
  getMenuBackEnd(){
    return this._http.get('/page/menuBack').toPromise();
  }
  getMenuPageList() {
    return this._http.get('/page/menuPageList').toPromise();
  }
  getMenuForCanonical() {
    return this._http.get('/page/menu_for_canonical').toPromise();
  }
  getMenus(){

    if(this._cookie.get('pages')){
      console.log('PAGE USE COOKIE');
      return new Promise((resolve, reject)=>{
        try{
          resolve(JSON.parse(localStorage.getItem('pages')))
        }catch(err){
          this._cookie.remove('pages');
          reject(err);
        }
      })
    }else{
      console.log('PAGE USE Service');
      return this._http.get('/page/menuFront').toPromise();
    }
  }

}
