import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';


import { ModalViewSlipComponent } from '@cms/system/modal/modal-view-slip/modal-view-slip.component';
import { ModalEditConfirmpaymentComponent } from '@cms/system/modal/modal-edit-confirmpayment/modal-edit-confirmpayment.component';
import { ModalCreateConfirmpaymentComponent } from '@cms/system/modal/modal-create-confirmpayment/modal-create-confirmpayment.component';
import { ModalCreateProductCategoryComponent } from '@cms/system/modal/modal-create-product-category/modal-create-product-category.component';
import { ModalProductInCategoryComponent } from '@cms/system/modal/modal-product-in-category/modal-product-in-category.component';
import { ModalAddeditBankAccountComponent } from '@cms/system/modal/modal-addedit-bank-account/modal-addedit-bank-account.component';
import { ModalCreateBlogCategoryComponent } from '@cms/system/modal/modal-create-blog-category/modal-create-blog-category.component';
import { ModalEditMenuComponent } from '@cms/system/modal/modal-edit-menu/modal-edit-menu.component';
import { ModalCreateMenuComponent } from '@cms/system/modal/modal-create-menu/modal-create-menu.component';
import { ModalAddEditCouponComponent } from '@cms/system/modal/modal-add-edit-coupon/modal-add-edit-coupon.component';
import { ModalAddEditDiscountConditionComponent } from '@cms/system/modal/modal-add-edit-discount-condition/modal-add-edit-discount-condition.component';

import { SharedModule } from './shared.module';
import { ModalAddpropfromProductComponent } from '@cms/system/modal/modal-addpropfrom-product/modal-addpropfrom-product.component';
import { ModalTagComponent } from '@cms/system/modal/modal-tag/modal-tag.component';
import { ModalAddproductComponent } from '@cms/system/modal/modal-addproduct/modal-addproduct.component';
import { ModalCreateCustomerComponent } from '@cms/system/modal/modal-create-customer/modal-create-customer.component';
import { ModalEditLangMessageComponent } from '@cms/system/modal/modal-edit-lang-message/modal-edit-lang-message.component';
import { ModalViewCustomerComponent } from '@cms/system/modal/modal-view-customer/modal-view-customer.component';
import { ModalExportOrderComponent } from '@cms/system/modal/modal-export-order/modal-export-order.component';
import { ModalExportConfirmpaymentComponent } from '@cms/system/modal/modal-export-confirmpayment/modal-export-confirmpayment.component';
import { ModalPrintDeliveryComponent } from '@cms/system/modal/modal-print-delivery/modal-print-delivery.component';
import { ModalExportEpaymentComponent } from '@cms/system/modal/modal-export-epayment/modal-export-epayment.component';
import { ModalForgotpasswordComponent } from '@cms/pages/modal/modal-forgotpassword/modal-forgotpassword.component';
import { ModalEditProfileComponent } from '@cms/pages/modal/modal-edit-profile/modal-edit-profile.component';
import { ModalEditBillingAddressComponent } from '@cms/pages/modal/modal-edit-billing-address/modal-edit-billing-address.component';
import { ModalAddEditSendingAddressComponent } from '@cms/pages/modal/modal-add-edit-sending-address/modal-add-edit-sending-address.component';
import { ModalQrComponent } from '@cms/pages/modal/modal-qr/modal-qr.component';
import { ModalCreditComponent } from '@cms/pages/modal/modal-credit/modal-credit.component';
import { ModalShowLinkPageComponent } from '@cms/system/modal/modal-show-link-page/modal-show-link-page.component';
import { ModalAddEmailComponent } from '@cms/system/modal/modal-add-email/modal-add-email.component';
import { ModalChangeEmailPasswordComponent } from '@cms/system/modal/modal-change-email-password/modal-change-email-password.component';
import { ModalChangeEmailQuotaComponent } from '@cms/system/modal/modal-change-email-quota/modal-change-email-quota.component';
import { ModalContactComponent } from '@cms/pages/modal/modal-contact/modal-contact.component';
import { ModalPrintQRComponent } from '@cms/system/modal/modal-print-qr-product/modal-print-qr-product.component';
import { OrderHistoryMessengerComponent } from '../system/modules/shared-order/order-history-messenger/order-history-messenger.component';
import { ModalTransferSlipComponent } from '@cms/pages/modal/modal-transfer-slip/modal-transfer-slip.component';
import { ModalOrderLinkShippingAddress } from '@cms/system/modal/modal-orderlink-shipping-address/modal-orderlink-shipping-address.component';
import { ConfirmpaymentModalComponent } from '@cms/system/modal/confirmpayment/confirmpayment.component';
import { ShopeeComponent } from '@cms/system/shopee/shopee.component';
import { ModalCreateShopeeProductComponent } from '@cms/system/modal/modal-create-shopee-product/modal-shopee-product';
import { ModalEditShopeeProductComponent } from '@cms/system/modal/modal-edit-shopee-product/modal-edit-shopee-product.component';
import { ModalCreditRedirectComponent } from '@cms/pages/modal/modal-credit-redirect/modal-credit-redirect.component';
import { ModalSearchByTagComponent } from '@cms/pages/modal/modal-search-by-tag/modal-search-by-tag.component';
import { ModalLoginComponent } from '@cms/pages/modal/modal-login/modal-login.component';
import { ModalEditProductsComponent } from '@cms/system/modal/modal-edit-products/modal-edit-products.component';
import { ModalDeleteShopeeProductComponent } from '@cms/system/modal/modal-delete-shopee-product/modal-delete-shopee-product.component';
import { ExtensSmSionComponent } from '@cms/system/extension/sms/sms.component';
import { ModalPrintOrdersComponent } from '@cms/system/modal/modal-print-orders/modal-print-orders.component';
import { RouterModule } from '@angular/router';
import { ModalExpireComponent } from '@cms/system/modal/modal-expire/modal-expire.component';
import { LazadaComponent } from '@cms/system/lazada/lazada.component';
import { OrderZortComponent } from '@cms/system/modules/shared-order/order-zort/order-zort.component';
import { ModalErrorComponent } from '@cms/system/modal/modal-error/modal-error.component';
import { ModalEditLazadaProductComponent } from '@cms/system/modal/modal-edit-lazada-product/modal-edit-lazada-product.component';
import { ModalGetLazadaOrderComponent } from '@cms/system/modal/modal-get-lazada-order/modal-get-lazada-order.component';
import { ModalGetShopeeOrderComponent } from '@cms/system/modal/modal-get-shopee-order/modal-get-shopee-order.component';
import { JDComponent } from '@cms/system/jd/jd.component';
import { ModalJDProductComponent } from '@cms/system/modal/modal-jd-product/modal-jd-product.component';
import { ModalAddSkuFromKetshopComponent } from '@cms/system/modal/modal-add-sku-from-ketshop/modal-add-sku-from-ketshop.component';
import { ModalGetJDOrderComponent } from '@cms/system/modal/modal-get-jd-order/modal-get-jd-order.component';
import { ModalExportDeliveryCreditComponent } from '@cms/system/modal/modal-export-delivery-credit/modal-export-delivery-credit.component';
import { ModalDeleteJDProductComponent } from '@cms/system/modal/modal-delete-jd-product/modal-delete-jd-product.component';
import { ModalDeleteLazadaProductComponent } from '@cms/system/modal/modal-delete-lazada-product/modal-delete-lazada-product.component';
import { ModalAddProductRelatedComponent } from '@cms/system/modal/modal-add-product-related/modal-add-product-related.component';
import { ModalAddBlogRelatedComponent } from '@cms/system/modal/modal-add-blog-related/modal-add-blog-related.component';

var _entryComponentsModal = [
  ModalViewSlipComponent,
  ModalEditConfirmpaymentComponent,
  ModalCreateConfirmpaymentComponent,
  ModalCreateProductCategoryComponent,
  ModalProductInCategoryComponent,
  ModalAddeditBankAccountComponent,
  ModalCreateBlogCategoryComponent,
  ModalEditMenuComponent,
  ModalCreateMenuComponent,
  ModalAddEditCouponComponent,
  ModalAddEditDiscountConditionComponent,
  ModalAddpropfromProductComponent,
  ModalAddProductRelatedComponent,
  ModalAddBlogRelatedComponent,
  ModalTagComponent,
  ModalAddproductComponent,
  ModalCreateCustomerComponent,
  ModalEditLangMessageComponent,
  ModalViewCustomerComponent,
  ModalExportOrderComponent,
  ModalExportConfirmpaymentComponent,
  ModalPrintDeliveryComponent,
  ModalPrintOrdersComponent,
  ModalExportEpaymentComponent,
  ModalForgotpasswordComponent,
  ModalLoginComponent,
  ModalEditProfileComponent,
  ModalEditBillingAddressComponent,
  ModalAddEditSendingAddressComponent,
  ModalQrComponent,
  ModalCreditComponent,
  ModalShowLinkPageComponent,
  ModalAddEmailComponent,
  ModalChangeEmailPasswordComponent,
  ModalChangeEmailQuotaComponent,
  ModalContactComponent,
  ModalPrintQRComponent,
  OrderHistoryMessengerComponent,
  OrderZortComponent,
  ModalTransferSlipComponent,
  ModalOrderLinkShippingAddress,
  ConfirmpaymentModalComponent,
  ShopeeComponent,
  LazadaComponent,
  JDComponent,
  ModalCreateShopeeProductComponent,
  ModalJDProductComponent,
  ModalAddSkuFromKetshopComponent,
  ModalEditShopeeProductComponent,
  ModalEditLazadaProductComponent,
  ModalGetLazadaOrderComponent,
  ModalGetJDOrderComponent,
  ModalGetShopeeOrderComponent,
  ModalDeleteShopeeProductComponent,
  ModalDeleteLazadaProductComponent,
  ModalDeleteJDProductComponent,
  ModalCreditRedirectComponent,
  ModalSearchByTagComponent,
  ModalEditProductsComponent,
  ModalExpireComponent,
  ModalErrorComponent,
  ExtensSmSionComponent,
  ModalExportDeliveryCreditComponent
]

var _pipes = [
]

var _directives = [
]

var _Components = [
  ..._pipes,
  ..._entryComponentsModal,
  ..._directives
]

var _modules = [
  SharedModule,
  RouterModule
]

@NgModule({
  declarations: [
    ..._Components
  ],
  entryComponents:[
    ..._entryComponentsModal
  ],
  imports: [
    CommonModule,
    ..._modules
  ],
  providers:[

  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    ..._Components
  ]
})
export class ModalsModule { }
