import { Component, OnInit, Input } from '@angular/core';
import { ProductsService } from '@cms/services/products.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal'; 
import { find, cloneDeep } from 'lodash';
@Component({
    selector: 'app-modal-product-related',
    templateUrl: './modal-add-product-related.component.html',
    styleUrls: ['./modal-add-product-related.component.css']
})
export class ModalAddProductRelatedComponent implements OnInit {
    @Input() data: any;
    message_duration: number = 4000;
   
    constructor(
        private modal: NzModalRef,
        private message: NzMessageService,
        private Service_Product: ProductsService
    ) { }
    model: any = {
        lang: "lang1",
        status: "",
        search: "",
        cate_id: "",
        group: "",
        page: 1,
        perpage: 10,
        ids: "",
        orderby: "id",
        "sort": "desc"
    }
    product: any = [];
    total: any = 0;
    selecteditem: any = [];
    allcategory: any = [];
    current_product_id:any = null;

    async ngOnInit() {
        this.allcategory = await this.Service_Product.productMainCategory();
        this.allcategory.unshift({ id: "-1", lang1: "Uncategory", lang2: "Uncategory", lang3: "Uncategory", lang4: "Uncategory" });
        this.allcategory.unshift({ id: "", lang1: "All", lang2: "All", lang3: "All", lang4: "All" });
        this.search();
        this.selecteditem = cloneDeep(this.data.product_related);
        this.current_product_id = this.data.product_id;
    }
    pagechage(page) {
        this.model.page = page;
        this.search();
    }
    deleteSelected(i) {
        this.selecteditem.splice(i, 1);
    }
    addProduct(data) {
        let chk = find(this.selecteditem, function (o) { return o.id == data.id; });
        if (!chk) {
            this.selecteditem.push(data);
        } 
    }
    search() {
        this.Service_Product.searchProduct(this.model).then((res: any) => {
            console.log(res);
            this.product = res.data;
            this.total = res.count;
        }).catch(data => {
            console.log(data.error.message);
        });
    }
    ok() {
        this.modal.close(this.selecteditem);
    }
}