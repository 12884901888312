import { Component, OnInit, Input, HostListener, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { ProductsService } from '@cms/services/products.service';
import { UtilService } from '@cms/services/util.service';
import { OrderService } from '@cms/services/order.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { find } from 'lodash';
import { interval } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ModalOrderLinkShippingAddress } from '@cms/system/modal/modal-orderlink-shipping-address/modal-orderlink-shipping-address.component';

@Component({
  selector: 'app-open-order-link',
  templateUrl: './open-order-link.component.html',
  styleUrls: ['./open-order-link.component.css']
})
export class OpenOrderLinkComponent implements OnInit, AfterViewInit {

  @Input() value:any
  @Input() state:string = 'create'
  @Input() button:string = 'เปิดบิล'


  prod_model: any = {
    lang: "lang1",
    status: "",
    search: "",
    cate_id: "",
    group: "",
    page: 1,
    perpage: 10,
    ids: "",
    orderby: "id",
    sort: "desc"
  }
  statusname:any = {
    "0": "ปิด",
    "1": "เปิด"
  }
  lang:any = [];
  selectedLang:string;
  product:any = [];
  total:number = 0;
  loading:boolean = true;
  allcategory:any = [];
  groupList:any = [];

  heightTable: number = 550

  model: any = {
    user_id: -1,
    shipping_address_id: -1,
    details: [],
    note: "",
    social: "",
    discountaddon: 0,
    delivery: null,
    facebook_id: "",
    selectedcustomer: null
  }
  summary: any = {
    vat: null,
    subtotal: null,
    total: 0,
    vatvalue: null,
    selecteddelivery: null,
    discountaddon: 0,
    promotion_detail: null,
    discount: 0,
    freeshipping: 0,
    delichargeaddon: 0
  }
  social_list:any = [];

  subscription_inuse:any;

  constructor(
    private drawerRef: NzDrawerRef<string>,
    private Service_Product: ProductsService,
    private Service_Util: UtilService,
    private Service_Order: OrderService,
    private message: NzMessageService,
    private modalService: NzModalService,
    private chRef: ChangeDetectorRef
    ) { }

    async ngOnInit() {
      this.lang = await this.Service_Util.getLangs();
      this.allcategory = await this.Service_Product.productMainCategory();
      this.allcategory.unshift({ id: "-1", lang1: "Uncategory", lang2: "Uncategory", lang3: "Uncategory", lang4: "Uncategory" });
      this.allcategory.unshift({ id: "", lang1: "All", lang2: "All", lang3: "All", lang4: "All" });
      this.selectedLang = this.lang[0].ref;
      // if (localStorage.product_filter){
      //   try{
      //     let _mode = JSON.parse(localStorage.product_filter);
      //     this.prod_model = _mode;
      //   }catch(e){
      //   }
      // }
      this.search();
      this.searchGroup("");


      try{
        this.model.social = this.value.type
      }catch(err){

      }

     // console.log(this.value)

      try{
        this.model.facebook_id = this.value.user.info.id
      }catch(err){

      }

      if(this.state == "edit"){
        this.Service_Order.orderLinkbyID(this.value.id).then( (res:any) =>{
          //console.log(res);
          if(res.status != 0){
             this.close()
          }
          this.model = res
          this.summary.discountaddon = this.model.discountaddon || 0;
          this.isInuse(this.model);
          setTimeout(() => {
            if(this.state == "create"){
              this.calculate()
            }else{
              this._calculate()
            }
          },150);
      }).catch(res=>{
          this.close()
      });

      }

    }

    @HostListener('window:resize', ['$event'])
    onWindowResize($event:any) {
        this.heightTable = this.tableHeight(document.body.clientHeight)
    }

    ngAfterViewInit(){
       setTimeout(()=>{
            this.heightTable = this.tableHeight(document.body.clientHeight)

       },150)
    }

    tableHeight(height:number){
        return (height-332)
    }

    close(){
      this.drawerRef.close();
    }

    async checkInuseActivity(obj) {
      // console.log(obj);
      let rs = await this.Service_Util.inuseActivity({ type: "orderlink", param_id: obj.id });
      //console.log(rs);
      return rs;
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  unsubscribe() {
      if (this.subscription_inuse) {
          this.subscription_inuse.unsubscribe();
      }
  }

  editShippingAddress(){
    const modal = this.modalService.create({
      nzTitle: 'ที่อยู่จัดส่ง',
      nzContent: ModalOrderLinkShippingAddress,
      nzStyle: { top: '20px', padding: '0px' },
      nzWidth: "50%",
      nzKeyboard: false,
      nzMaskClosable: false,
      nzFooter: null,
      nzComponentParams: {
        data: {
          state: 'edit',
          selectedcustomer: this.model.selectedcustomer
        }
      }
    }).afterClose.subscribe((result) => {
      modal.unsubscribe()
      console.log(result);
      this.model.selectedcustomer = result;
    });
  }
  addShippingAddress(){
    const modal = this.modalService.create({
      nzTitle: 'ที่อยู่จัดส่ง',
      nzContent: ModalOrderLinkShippingAddress,
      nzStyle: { top: '20px',padding: '0px' },
      nzWidth: "50%",
      nzKeyboard: false,
      nzMaskClosable: false,
      // nzOnOk: (componentInstance) => {
      //   return componentInstance.ok();
      // },
      // nzOnCancel: (componentInstance) => {

      // },
      nzFooter: null,
      nzComponentParams: {
        data: {
          state: 'create'
        }
      }
    }).afterClose.subscribe((result) => {
      modal.unsubscribe()
      console.log(result);
      this.model.selectedcustomer = result;
    });
  }


  async isInuse(obj) {
      let inuse: any = await this.checkInuseActivity(obj);
      if (!inuse.available) {
          this.modalService.error({
              nzTitle: 'Error',
              nzContent: `${inuse.user} กำลังทำการแก้ไขรายการนี้อยู่`,
              nzOkText: 'Back',
              nzOnOk: () => {
                  this.close()
              }
          });
          return;
      } else {
          let interval_checkstatus = interval(5000);
          this.subscription_inuse = interval_checkstatus.subscribe(this.checkInuseActivity.bind(this, obj));
      }
  }

    searchGroup(event:string){
      console.log(event);
      let obj = {
        name: event
      }
      this.Service_Product.searchProductGroup(obj).then((res:any) => {
        console.log(res);
        this.groupList = res;
        // this.groupList.unshift({ group: ""})
      }).catch(data => {
        console.log(data.error.message);
      });
    }

    clearfilter(){
      this.prod_model = {
        lang: "lang1",
        status: 1,
        search: "",
        cate_id: "",
        group: "",
        page: 1,
        perpage: 10,
        ids: "",
        orderby: "id",
        "sort": "desc"
      }
      this.search();
    }

    search(){
      // localStorage.product_filter = JSON.stringify(this.prod_model);
      this.loading = true;
      this.Service_Product.searchProduct(this.prod_model).then((res: any) => {
      //  console.log(res);
        this.loading = false;
        this.product = res.data;
        this.total = res.count;

        this.chRef.detectChanges();
      }).catch(data => {
        this.loading = false;
        //console.log(data.error.message);
      });
    }


    pagechage(page){
      this.prod_model.page = page;
      this.search();
    }
    sort(orderby){
      this.prod_model.orderby = orderby;
      if (this.prod_model.sort == 'desc') {
        this.prod_model.sort = 'asc';
      } else {
        this.prod_model.sort = 'desc';
      }
      this.search();
    }



    discountaddonchange(e) {
      e = e || 0;
      this.summary.discountaddon = e;
      if (this.summary.discountaddon > ( this.summary.subtotal - this.summary.discount )){
        this.summary.discountaddon = this.summary.subtotal - this.summary.discount;
      }
      //console.log(this.summary);
      this.calculatepricedetail();
    }

    calculatepricedetail() {
      let subtotal = 0;
      let deliprice = 0;
      if (this.summary.selecteddelivery) {
        if (this.summary.selecteddelivery.type == "shippop") {
          deliprice = Number(this.summary.selecteddelivery.price) + Number(this.summary.delichargeaddon);
        } else {
          deliprice = Number(this.summary.selecteddelivery.price);
        }
      }
      for (let i = 0; i < this.model.details.length; i++) {
        let current = this.model.details[i];
        subtotal += (Number(current.price) * Number(current.qty));
      }
      this.summary.subtotal = subtotal;
      this.summary.vatvalue = (Number(this.summary.vat) * 0.01) * (subtotal - Number(this.summary.discountaddon) - Number(this.summary.discount));
      this.summary.total = subtotal - Number(this.summary.discount) - Number(this.summary.discountaddon) + this.summary.vatvalue + deliprice;

      this.chRef.detectChanges();

    }
    calculate() {

      if (!this.model.user_id) {
        // this.message.error(`โปรดเลือกรายชื่อลูกค้า`, { nzDuration: this.message_duration });
        return;
      }
      if (!this.model.details.length) {
        // this.message.error(`โปรดเลือกรายชื่อลูกค้า`, { nzDuration: this.message_duration });
        this.summary.vat = null;
        this.summary.vatsubtotal = null;
        this.summary.total = 0;
        this.summary.vatvalue = null;
        this.summary.selecteddelivery = null;
        // this.summary.discountaddon = 0;
        this.summary.promotion_detail = null;
        this.summary.discount = 0;
        this.summary.freeshipping = 0;
        this.summary.delichargeaddon = 0;

        return;
      }

      this.summary.selecteddelivery = null;
      //this.message.error(``, { nzDuration: this.message_duration });
      this.Service_Order.discountanddelivery(this.model).then((res: any) => {
        //console.log(res);
        this.summary.vat = res.vatrate;
        this.summary.promotion_detail = res.promotion_detail;
        this.summary.discount = res.discount;
        this.summary.freeshipping = res.freeshipping;
        this.summary.delichargeaddon = res.delichargeaddon
        for (let i = 0; i < res.delivery_normal.length; i++) {
          res.delivery_normal[i].type = "normal";
          res.delivery_normal[i].value = res.delivery_normal[i].id;
        }

        for (let i = 0; i < res.delivery_shippop.length; i++) {
          res.delivery_shippop[i].type = "shippop";
          res.delivery_shippop[i].value = res.delivery_shippop[i].courier_code;
        }

        this.calculatepricedetail();
      }).catch((data) => {
        this.message.error(`${data.error.message}`, { nzDuration: 6000 });
      })
    }


    _calculate() {
      if (!this.model.details.length) {
          // this.message.error(`โปรดเลือกรายชื่อลูกค้า`, { nzDuration: this.message_duration });
          this.summary.vat = null;
          this.summary.vatsubtotal = null;
          this.summary.total = 0;
          this.summary.vatvalue = null;
          this.summary.selecteddelivery = null;
          // this.summary.discountaddon = 0;
          this.summary.promotion_detail = null;
          this.summary.discount = 0;
          this.summary.freeshipping = 0;
          this.summary.delichargeaddon = 0;

          return;
      }

      this.summary.selecteddelivery = null;
      //this.message.error(``, { nzDuration: this.message_duration });
      this.Service_Order.discountanddelivery(this.model).then((res: any) => {
          console.log(res);
          this.summary.vat = res.vatrate;
          this.summary.promotion_detail = res.promotion_detail;
          this.summary.discount = res.discount;
          this.summary.freeshipping = res.freeshipping;
          this.summary.delichargeaddon = res.delichargeaddon
          for (let i = 0; i < res.delivery_normal.length; i++) {
              res.delivery_normal[i].type = "normal";
              res.delivery_normal[i].value = res.delivery_normal[i].id;
          }

          for (let i = 0; i < res.delivery_shippop.length; i++) {
              res.delivery_shippop[i].type = "shippop";
              res.delivery_shippop[i].value = res.delivery_shippop[i].courier_code;
          }

          this.calculatepricedetail();
      }).catch((data) => {
          this.message.error(`${data.error.message}`, { nzDuration: 6000 });
      })
  }

    addProduct(data:any){
      //console.log(this.model.details)

      if(this.state == "create"){
        let chk = find(this.model.details, function (o) { return o.id == data.id; });
        if(!chk){
          data.qty = 1;
          this.model.details = [...this.model.details, data]
        }else{
          chk.qty++;
          if (chk.qty > chk.instock && !chk.pre_order){
            chk.qty = chk.instock
          }
        }
      }else{
        let chk = find(this.model.details, function (o) {
          if(o.product_id){
            return o.product_id == data.id;
          }else{
            return o.id == data.id;
          }
         });
        if(!chk){
          data.qty = 1;
          this.model.details = [...this.model.details, data]
        }else{
          chk.qty++;
        }
      }


      if(this.state == "create"){
        this.calculate()
      }else{
        this._calculate()
      }

    }

    removeProduct(data:any){
      if(this.state == "create"){
        this.model.details  =  this.model.details.filter(d => d.id !== data.id);
      }else{
        this.model.details  =  this.model.details.filter(d => {
          if(d.product_id){
            return d.product_id !== data.product_id
          }else{
            return d.id !== data.id
          }
        });

      }

      if(this.state == "create"){
        this.calculate()
      }else{
        this._calculate()
      }


    }

    updateQty(obj:any, operator: string = '+-'){
      if(this.state == "create"){
        obj.qty = Number(obj.qty)
        if(operator == "+"){
          if (obj.qty < obj.instock || obj.pre_order){
                obj.qty +=1
            }
        }
        if(operator == "-"){
            if(obj.qty > 1){
                obj.qty -=1
            }
        }

        if(operator == "+-"){
          if (obj.qty > obj.instock && !obj.pre_order){
                obj.qty = obj.instock
            }

            if(obj.qty < 1){
                obj.qty = 1
            }

        }
      }else{

        obj.qty = Number(obj.qty)
        if(operator == "+"){
          obj.qty +=1
        }

        if(operator == "-"){
            if(obj.qty > 1){
                obj.qty -=1
            }
        }

        if(operator == "+-"){

            if(obj.qty < 1){
                obj.qty = 1
            }

        }


      }
      if(this.state == "create"){
        this.calculate()
      }else{
        this._calculate()
      }
  }

    save(){
      console.log(this.model);
      if (this.model.details.length == 0) {
        this.message.error(`โปรดเลือกสินค้า`, { nzDuration: 4000 });
        return;
      }
      this.model.discountaddon = this.summary.discountaddon;
      // return;
      $('.theme-loader').fadeIn();

      if(this.state == "create"){
        this.Service_Order.createOrderLink(this.model).then((res: any) => {
          console.log(res);
          this.drawerRef.close(res);
          $('.theme-loader').fadeOut();
        }).catch(data => {
          this.message.error(`${data.error.message}`, { nzDuration: 6000 });
        }).finally(() => {
          $('.theme-loader').fadeOut();
        });
      }else{
        this.Service_Order.updateOrderLink(this.model).then((res: any) => {
          console.log(res);
          this.drawerRef.close(res);
          $('.theme-loader').fadeOut();
        }).catch(data => {
          this.message.error(`${data.error.message}`, { nzDuration: 6000 });
        }).finally(() => {
          $('.theme-loader').fadeOut();
        });
      }
    }

}
