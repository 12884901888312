import { Component, OnInit } from '@angular/core';
import { OrderService } from '@cms/services/order.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { Etc } from '@cms/util/etc';
import { UtilService } from '@cms/services/util.service';

@Component({
  selector: 'app-modal-print-orders',
  templateUrl: './modal-print-orders.component.html',
  styleUrls: ['./modal-print-orders.component.css']
})
export class ModalPrintOrdersComponent implements OnInit {

  constructor(
    private message: NzMessageService,
    private Service_Order: OrderService,
    private _util: UtilService,
    private _etc: Etc,
    private _NzDrawerRef: NzDrawerRef
  ) { }

  selected_order:any[] = []
  orders:any[] = []
  total:any = 0;
  loading:boolean = false
  check_all_status:boolean = false
  paymentimg:any;
  statusimg:any;
  shippopimg:any = {};
  modelsearch:any = {
    startdate: "",
    enddate: "",
    status: "",
    ordercode: "",
    email: "",
    trackcode: "",
    search: "",
    paymenttype: "",
    created_by: "",
    "page": 1,
    "perpage": 10,
    confirm_payment: "1"
  }
  channels:any = {}
  listChannels:any[] = []

  async ngOnInit() {
    this.listChannels = await this._util.getChannels() as any[]
    for(let ch of this.listChannels){
      this.channels[ch.value] = {
        img: ch.img, 
        name: ch.name
      }
    }
    this.statusimg = this._etc.orderstatus_img();
    this.paymentimg = this._etc.payment_img();
    this.shippopimg = this._util.getShippopImage();
    this.search()
  }

  search(clearpage:boolean = false){
    this.loading = true
    this.Service_Order.listOrders(this.modelsearch).then((res:any)=>{
      // console.log(res)
      // this.total = res.count
      // this.orders = res.data
      this.orders = []
      for (let obj of res.data) {
        if (this.selected_order.find(x => x.ordercode == obj.ordercode)) {
          obj["checked"] = true;
          this.orders = [...this.orders, obj];
        } else {
          obj["checked"] = false;
          this.orders = [...this.orders, obj];
        }
      }
      this.total = res.count;
      this.check_status_all()

    }).catch(err =>{

    }).finally(()=>{
      this.loading = false
    })
  }

  del_select_order(obj: any) {

    this.selected_order = this.selected_order.filter(
      d => d.ordercode !== obj.ordercode
    );

    var orderIndex = this.orders.findIndex(
      x => x.ordercode == obj.ordercode
    );

    if (orderIndex !== -1) {
      this.orders[orderIndex]["checked"] = false;
    }

    this.check_status_all()

  }

  addAll(){
    //console.log('auto', this.check_all_status_auto)
    //console.log(this.check_all_status)
    if(this.check_all_status){
      for(let obj of this.orders){
        this.del_select_order(obj)
      }
    }else{
      for(let obj of this.orders){
        if (this.selected_order.find(x => x.ordercode == obj.ordercode)) {

        }else{
          this.selectOrder(obj)
        }
      }
    }
  }

  selectOrder(obj: any) {
    if (!this.selected_order.find(x => x.ordercode == obj.ordercode)) {
      obj["checked"] = true;
      this.selected_order = [...this.selected_order, obj];
    }else{
      this.del_select_order(obj)
    }
    this.check_status_all()
  }


  check_status_all(){
    this.check_all_status = true
    for (let obj of this.orders) {
      if (this.selected_order.find(x => x.ordercode == obj.ordercode)) {

      } else {
        obj["checked"] = false;
        this.check_all_status = false
      }
    }

    if(this.orders.length == 0){
      this.check_all_status = false
    }
  }


  pagechage(page){
    this.modelsearch.page = page;
    this.search();
  }

  ok() {
    if (this.selected_order.length == 0 ){
      this.message.error("Please select order");
      return false;
    }

    var _html = ''
    _html+=`
      <script src="${window.location.origin}/assets/js/qrcode.min.js"></script>
      `
      for(let order of this.selected_order){
        _html+=`
        ${this._etc.getOrderHtml(order)}
        <script>
        new QRCode(document.getElementById('ordercode_${order.ordercode}'), { width: 75, height: 75, text: '${order.ordercode}' });
        </script>
        `
      }
      _html+=`
      <script>
        window.print()
        // window.close()
      </script>
    `

    var _w = window.open()
    _w.document.write(_html)

  }

  close(){
    this._NzDrawerRef.close()
  }

}
