import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UtilService } from '@cms/services/util.service';
import { ProductsService } from '@cms/services/products.service';
@Component({
  selector: 'app-modal-create-product-category',
  templateUrl: './modal-create-product-category.component.html',
  styleUrls: ['./modal-create-product-category.component.css']
})
export class ModalCreateProductCategoryComponent implements OnInit {
  @Input() data: any;

  constructor(
    private modal: NzModalRef,
    private message: NzMessageService,
    private Service_Util: UtilService,
    private Service_Product: ProductsService
  ) { }
  lang:any = [];
  message_duration:number = 7000;
  model:any = {
    lang1: "",
    lang2: "",
    lang3: "",
    lang4: ""
  }
  async ngOnInit() {
    this.lang = await this.Service_Util.getLangs();
    console.log(this.lang);
  }

  async ok(){
    
    for (let i = 0; i < this.lang.length; i++) {
      console.log(this.model[this.lang[i].ref]);
      if (this.model[this.lang[i].ref] == null || this.model[this.lang[i].ref].trim() == "") {
        this.message.error(`Please fill tag name ( ${this.lang[i].name} )`, { nzDuration: this.message_duration });
        return false;
      }
    }
    
    try{
      await this.Service_Product.createProductCategory(this.model);
      this.modal.close({ data: true });
    }catch(e){
      console.log(e);
      this.message.error(e.error.message, { nzDuration: this.message_duration });
      return false;
    } 

  }
}
