import { Component, OnInit } from '@angular/core';
import { SettingService } from '@cms/services/setting.service';
import { apiError } from '@cms/interface/interface';
import { FeatureService } from '@cms/services/feature.service';
import { cloneDeep, merge } from 'lodash'

type  IDisplayType = 'text' | 'percent';
type  IDisplaySize = '' | 'medium' | 'large'

interface IButton {
  link: string;
  status: boolean;
}

interface ISetting {
  border_radius: number;
  border: number;
  border_size: number;
  border_color: string;
  name_position: string;
  name_color: string;    
  font_price: string;
  font_size_price: number;
  font_color_price: string;
  load_more: string | number;
  messenger_button: IButton;
  line_button: IButton;
  sale_tag_display: {
    type: IDisplayType,
    size: IDisplaySize
  }
}

@Component({
  selector: 'app-product-display',
  templateUrl: './product-display.component.html',
  styleUrls: ['./product-display.component.css']
})
export class ProductDisplayComponent implements OnInit {

	displayList = [
		{
			id: 1,
			desc: "สินค้าที่มีหมวดหมู่หลายชั้น",
			img: "/system/core-imgs/Product_Blog/product-tmp-01.jpg"
		},
		{
			id: 2,
			desc: "สินค้าที่มีหลากหลายหมวดหมู่",
			img: "/system/core-imgs/Product_Blog/product-tmp-02.jpg"
		},
		{
			id: 3,
			desc: "ต้องการแสดงหมวดหมู่ให้เด่น",
			img: "/system/core-imgs/Product_Blog/product-tmp-03.jpg"
		},
		{
			id: 4,
			desc: "สินค้าที่มีการกรองหลายระดับ",
			img: "/system/core-imgs/Product_Blog/product-tmp-04.jpg"
		},
		{
			id: 5,
			desc: "เหมาะกับสินค้าที่ไม่มีหมวดหมู่",
			img: "/system/core-imgs/Product_Blog/product-tmp-05.jpg"
		},
		{
			id: 6,
			desc: "สินค้าที่มีจำนวนมาก หมวดหมู่ไม่ซับซ้อน",
			img: "/system/core-imgs/Product_Blog/product-tmp-06.jpg"
		},
    {
			id: 7,
			desc: "เทมเพลตสำหรับ SandClock",
			img: "/system/core-imgs/sandclock/product-blog-tmt-07-custom.svg"
		},
  ];

  displayListModel: number = 1
  modal_edit:boolean = false
  catalog_template_setting:number = 1
  product_img_class: string = 'horizontal'
  product_default_filter:number = 1
  product_blog_title_font:string = ''
  product_display_item_perpage:number = 12
  displayListPreview:any
  select_font_render:boolean = false
  options:any[] = []
  default_no_image:string = '/assets/images/core-imgs/ex_imgBg_df.png'
  feature:any = false;
  product_list_font_size: number = 16
  catalog_social_share_setting:number = 0
  show_product_outstock:number = 0
  ecommerce_setting:number = 0
  catalog_contact_setting:number = 0
  product_details_gallery_pos: string = 'bottom';
  product_details_short_desc_pos: string = 'upper';
  price_setting:number = 0
  sku_setting:number = 0
  weight_setting:number = 0
  add_to_cart_now:boolean = false
  product_details_prop_style: string = 'dropdown';

  add_to_cart_now_setting:any = {
    format: "icon",
    align: "right"
  }

  button:any = {
    backgroundcolor: "#525e68",
    backgroundcolorhover: "#fff",
    border: "2",
    bordercolor: "#525e68",
    bordercolorhover: "#525e68",
    borderhover: "2",
    borderradius: "9",
    borderradiushover: "9",
    fontcolor: "#fff",
    fontcolorhover: "#525e68"
}
ecommerce:any = {
    backtotopbackgroundcolor: "#fac769",
    backtotopfontcolor: "#a66900",
    backtotophover: "#fac769",
    cartbuttoncolor: "#fac769",
    cartbuttonhover: "#fac769",
    menucarthover: "#cccccc",
    cartbuttonfontcolor: "#fff",
    cartbuttonbgfontcolor: "#000"
}

  setting: ISetting = {
    border_radius: 0,
    border: 0,
    border_size: 1,
    border_color: "#eee",
    name_position: "left",
    name_color: "#000",
    font_price: "",
    font_size_price: 16,
    font_color_price: "#000",
    load_more: "0",
    messenger_button: {
      link: "",
      status: false
    },
    line_button: {
      link: "",
      status: false
    },
    sale_tag_display: {
      type: 'text',
      size: ''
    }
  }
  _theme_color:any

  load_setting = {
    status: true,
    mobile: false,
    desktop: false
  }
  product_img_crop: boolean = false;

  constructor(
    private _setting: SettingService,
    private Service_Feature: FeatureService
  ) {

  }

  async ngOnInit() {
    this.feature = await this.Service_Feature.getFeature("PRODUCT_PAGE");
    if (!this.feature) {
      return;
    }
    this.default_no_image = localStorage.getItem('default_no_image')
    this._setting.searchOption({
      pop_key: [
        'product_img_class',
        'catalog_template_setting',
        'product_default_filter',
        'product_blog_title_font',
        'product_display_item_perpage',
        'product_list_font_size',
        'product_setting',
        'add_to_cart_now',
        'catalog_social_share_setting',
        'show_product_outstock',
        'ecommerce_setting',
        'catalog_contact_setting',
        'price_setting',
        'sku_setting',
        'weight_setting',
        'theme_value',
        'add_to_cart_now_setting',
        'product_details_prop_style',
        'sale_tag_display',
        'product_details_gallery_pos',
        'product_details_short_desc_pos',
        'product_img_crop'
      ]
    }).then((res:any[])=>{
      // console.log(res)
      this.options = res
      for(let obj of res){
        //console.log(obj)

        switch (obj.pop_key) {
          case 'add_to_cart_now_setting':
            let add_to_cart_now_setting = null
            try{
              add_to_cart_now_setting = JSON.parse(obj.pop_value)
              console.log('add_to_cart_now_setting', add_to_cart_now_setting)
            }catch(err){}
            if(!add_to_cart_now_setting || typeof add_to_cart_now_setting !== 'object'){
              add_to_cart_now_setting = this.add_to_cart_now_setting
            }
            this.add_to_cart_now_setting = add_to_cart_now_setting
            break;
          case 'add_to_cart_now':
            this.add_to_cart_now = Number(obj.pop_value)?true:false
            break;
          case 'weight_setting':
            this.weight_setting = Number(obj.pop_value)
            break;
          case 'sku_setting':
            this.sku_setting = Number(obj.pop_value)
            break;
          case 'price_setting':
            this.price_setting = Number(obj.pop_value)
            break;
          case 'catalog_social_share_setting':
            this.catalog_social_share_setting = Number(obj.pop_value)
            break;
          case 'show_product_outstock':
            this.show_product_outstock = Number(obj.pop_value)
            break;
          case 'ecommerce_setting':
            this.ecommerce_setting = Number(obj.pop_value)
            break;
          case 'catalog_contact_setting':
            this.catalog_contact_setting = Number(obj.pop_value)
            break;
          case 'catalog_template_setting':
            this.catalog_template_setting = Number(obj.pop_value)
            this.displayListPreview = this.displayList.find(x => x.id == this.catalog_template_setting)
            break;
          case 'product_img_class':
            this.product_img_class = obj.pop_value
            break;
          case 'product_default_filter':
            this.product_default_filter = Number(obj.pop_value)
            break;
          case 'product_blog_title_font':
            this.product_blog_title_font = obj.pop_value
            this.select_font_render = true
            break;
          case 'product_display_item_perpage':
            this.product_display_item_perpage = Number(obj.pop_value)
            break;
          case 'product_list_font_size':
              this.product_list_font_size = Number(obj.pop_value)
              break;
          case 'product_setting':
              //console.log(obj.pop_value)
              var _data = obj.pop_value
              try{
                _data = JSON.parse(_data)
              }catch(err){

              }
              //console.log(typeof _data)
              if(typeof _data == 'object'){
                //console.log(obj.pop_value)
               if(_data){
                this.setting = {
                  ...this.setting,
                  ..._data
                }
               }
              }

              if(!this.setting.hasOwnProperty('messenger_button')){
                this.setting['messenger_button'] = {
                  link: "",
                  status: false
                }
              }

              if(!this.setting.hasOwnProperty('line_button')){
                this.setting['line_button'] = {
                  link: "",
                  status: false
                }
              }

              if(!this.setting.hasOwnProperty('load_more')){
                this.setting['load_more'] = "0"
              }

              if(Number(this.setting['load_more']) == 0){
                this.load_setting.desktop = false
                this.load_setting.mobile = false
                this.load_setting.status = false 
              }else if(Number(this.setting['load_more']) == 1){
                this.load_setting.desktop = false
                this.load_setting.mobile = true
                this.load_setting.status = true
              }else if(Number(this.setting['load_more']) == 2){
                this.load_setting.desktop = true
                this.load_setting.mobile = false
                this.load_setting.status = true
              }else if(Number(this.setting['load_more']) == 3){
                this.load_setting.desktop = true
                this.load_setting.mobile = true
                this.load_setting.status = true
              }

            break;
          case 'theme_value':
              this._theme_color = obj.pop_value
  
              try {
  
                  var _data = JSON.parse(obj.pop_value)
                  //console.log(_data);
                  window["_theme_color"] = _data
              } catch (e) {
  
                  eval(`${obj.pop_value} window._theme_color = _theme_color;`)
  
              }
              setTimeout(()=>{
                  try{
                      this.button = merge(this.button, cloneDeep(window["_theme_color"]["button"]))
                      this.ecommerce = merge(this.ecommerce, cloneDeep(window["_theme_color"]["ecommerce"]))
                  }catch(err){}
             },100)
            break;
          case 'product_details_prop_style':
            this.product_details_prop_style = obj.pop_value;
            break;
          case 'product_details_gallery_pos':
            this.product_details_gallery_pos = obj.pop_value;
            break;
          case 'product_details_short_desc_pos':
            this.product_details_short_desc_pos = obj.pop_value;
            break;
          case 'product_img_crop':
            this.product_img_crop = Number(obj.pop_value) ? true : false;
            break;
          default:
            break;
        }

      }

    }).catch((e: apiError)=>{
      console.log(e.error.message)
    })
    console.log(this.setting)
  }

  openModal(){
    this.displayListModel = this.catalog_template_setting
    this.modal_edit = true

  }

  handleOk(){
    this.catalog_template_setting = this.displayListModel
    this.displayListPreview = this.displayList.find(x => x.id == this.catalog_template_setting)
    this.modal_edit = false
  }

  handleCancel(){
    this.modal_edit = false
  }

  save(){

    for(let obj of this.options){
      switch (obj.pop_key) {
        case 'add_to_cart_now_setting':
          obj.pop_value = JSON.stringify(this.add_to_cart_now_setting)
        break;
        case 'add_to_cart_now':
          obj.pop_value = this.add_to_cart_now?1:0
          break;
        case 'catalog_template_setting':
          obj.pop_value = this.catalog_template_setting
          break;
        case 'product_img_class':
          obj.pop_value = this.product_img_class
          break;
        case 'product_default_filter':
          obj.pop_value = this.product_default_filter
          break;
        case 'product_blog_title_font':
          obj.pop_value = this.product_blog_title_font
          break;
        case 'product_display_item_perpage':
          obj.pop_value = this.product_display_item_perpage
          break;
        case 'product_list_font_size':
          obj.pop_value = this.product_list_font_size
          break;
        case 'product_setting':
          if(this.load_setting.status){

            if(this.load_setting.mobile){
              this.setting['load_more'] = 1
            }else if(this.load_setting.desktop){
              this.setting['load_more'] = 2
            }else{
              this.setting['load_more'] = 0
            }

            if(this.load_setting.mobile && this.load_setting.desktop){
              this.setting['load_more'] = 3
            }

          }else{
            this.setting['load_more'] = 0
          }
          obj.pop_value = JSON.stringify(this.setting)
          break;
        case 'catalog_social_share_setting':
          obj.pop_value = this.catalog_social_share_setting
          break;
        case 'show_product_outstock':
          obj.pop_value = this.show_product_outstock
          break;
        case 'ecommerce_setting':
          obj.pop_value = this.ecommerce_setting
          break;
        case 'price_setting':
          obj.pop_value = this.price_setting
          break;
        case 'sku_setting':
          obj.pop_value = this.sku_setting
          break;
        case 'weight_setting':
          obj.pop_value = this.weight_setting
          break;
        case 'catalog_contact_setting':
          obj.pop_value = this.catalog_contact_setting
          break;
        case 'product_details_prop_style':
          obj.pop_value = this.product_details_prop_style
          break;
        case 'product_details_gallery_pos':
          obj.pop_value = this.product_details_gallery_pos;
          break;
        case 'product_details_short_desc_pos':
          obj.pop_value = this.product_details_short_desc_pos;
          break;
        case 'product_img_crop':
          obj.pop_value = this.product_img_crop;
          break;
        default:
          break;
      }

    }

    $('#preloadwraper').fadeIn()
    this._setting.saveOption(this.options).then((res)=>{
      $('#preloadwraper').fadeOut()
      this.ngOnInit()
      // var url = decodeURI(this.router.url);
      // this.router.navigated = false;
      // this.router.navigate(['/system']).then(()=>this.router.navigate([url]));

    }).catch((e:apiError)=>{
      $('#preloadwraper').fadeOut()
      console.log(e)
    })

  }

  genStyle(){
    var s: string = `
        <style>
            .tmt-btn-all{
              background-color: ${this.button.backgroundcolor};
              color:  ${this.button.fontcolor};
              border: solid  ${this.button.border}px;
              border-color:  ${this.button.bordercolor};
              border-radius: ${this.button.borderradius}px;
            }
            .tmt-btn-all:hover{
              background-color: ${this.button.backgroundcolorhover};
              color:  ${this.button.fontcolorhover};
              border: solid  ${this.button.borderhover}px;
              border-color:  ${this.button.bordercolorhover};
              border-radius: ${this.button.borderradiushover}px;
            }

            .tmt-btn-all:focus{
              background-color: ${this.button.backgroundcolor};
              color:  ${this.button.fontcolor};
              border: solid  ${this.button.border}px;
              border-color:  ${this.button.bordercolor};
              border-radius: ${this.button.borderradius}px;
            }

            .tmt-btn-all.hover{
              background-color: ${this.button.backgroundcolorhover};
              color:  ${this.button.fontcolorhover};
              border: solid  ${this.button.borderhover}px;
              border-color:  ${this.button.bordercolorhover};
              border-radius: ${this.button.borderradiushover}px;
            }

            .static-bag{
                background-color: ${this.ecommerce.cartbuttoncolor};
            }
            .static-bag:hover{
                background-color: ${this.ecommerce.cartbuttonhover};
            }
            .static-top{
                background-color: ${this.ecommerce.backtotopbackgroundcolor};
            }
            .static-top i{
                color: ${this.ecommerce.backtotopfontcolor};
            }
            .static-top:hover{
                background-color: ${this.ecommerce.backtotophover};
            }

            .static-bag .number-on-bag-float{
              background-color: ${this.ecommerce.cartbuttonbgfontcolor};
              color: ${this.ecommerce.cartbuttonfontcolor};
            }

        </style>
    `

    return s
  }

  log() {
    console.log(this.setting)
  }
}

