import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class BlogsService {

  constructor(
    private _http:HttpClient
    ) { }

    BlogRelated(data:any){
      return this._http.post('/blog/blogRelated', data).toPromise();
    }

    searchBlog(data:any){
      return this._http.post('/blog/search', data).toPromise();
    }

    searchBlogFront(data:any){
      return this._http.post('/blog/searchFront', data).toPromise();
    }
    listBlogTag(){
      return this._http.get('/blog/listBlogTag').toPromise();
    }

    BlogCategory(){
      return this._http.get('/blog/category').toPromise();
    }
    BlogDetail(obj){
      return this._http.post('/blog/blogDetail',obj).toPromise();
    }
    allCategory(){
      return this._http.get('/blog/allCategory').toPromise();
    }
    updateCategory(obj){
      return this._http.put('/blog/category/'+obj.id, obj).toPromise();
    }
    updateCategorySort(obj){
      return this._http.post('/blog/categorySort',obj).toPromise();
    }

    createCategory(obj){
      return this._http.post('/blog/category', obj).toPromise();
    }

    deleteCategory(id){
      return this._http.delete('/blog/category/'+id).toPromise();
    }

    blogById(id: number){
        return this._http.get(`/blog/${id}`).toPromise();
    }

    createBlog(obj){
      return this._http.post(`/blog`,obj).toPromise();
    }

    updateBlog(obj){
      return this._http.put(`/blog/${obj.post_id}`, obj).toPromise();
    }

    deleteBlog(id){
      return this._http.delete(`/blog/${id}`).toPromise();
    }

    getCategoryIDS(obj:any){
      return this._http.post('/blog/searchCategoryByID',obj).toPromise();
    }

    getKetshopNews(){
      return this._http.get(`/blog/ketshopweb_new`).toPromise();
    }

    getKetshopFeature(){
      return this._http.get(`/blog/ketshopweb_feature`).toPromise();
    }

    getKetshopRecommend(){
      return this._http.get(`/blog/ketshopweb_recommend`).toPromise();
    }

    searchBlogIndex(data:any){
      return this._http.post('/blog/searchBlogIndex', data).toPromise();
    }


}
