import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'removeTag'
})
export class removeTagPipe implements PipeTransform {

  constructor() {
  }

  transform(v:string) {
    if(v == null || v == undefined){
      return "";
    }
      v = v.replace(/<\s*br\/*>/gi, "\n");
      // v = v.replace(/<\s*a.*href="(.*?)".*>(.*?)<\/a>/gi, " $2 (Link->$1) ");
    v = v.replace(/<\s*a.*href="(.*?)".*>(.*?)<\/a>/gi, "$2");
      v = v.replace(/<\s*\/*.+?>/ig, "\n");
      v = v.replace(/ {2,}/gi, " ");
      v = v.replace(/\n+\s*/gi, "\n\n");
      v = v.replace(/&nbsp;/g, " ");
      v = v.replace(/&ldquo;/g, "“");
      v = v.replace(/&quot;/g, '"');
      v = v.replace(/&#39;/g, "");
    v = v.replace(/&rdquo;/g, "”");
      v = v.replace(/&amp;/g, "&");
    v = v.replace(/&lt;/g, "<");
    v = v.replace(/&gt;/g, ">");
    v = v.replace(/&sbquo;/g, "‚");

    


      v = v.replace(/(\r\n|\n|\r)/gm, "");
    try{
        v = v.trim();
    }catch(e){}
    return `${v}`;
  }
}
