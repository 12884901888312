import { Component, OnInit, Input } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { ProductsService } from '@cms/services/products.service';
@Component({
  selector: 'ket-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.less']
})
export class KetProductFilterComponent implements OnInit {

  constructor(
    private nzDrawerRef: NzDrawerRef,
    protected Service_Product: ProductsService,
  ) { }

  page_name: any = "product";
  @Input() productTag: any[] = [];
  @Input() allcate: any = null;
  @Input() _model : any = null;

  @Input() priceRange : any = null;
  @Input() priceRangeValue : any = null;
  @Input() renderType : any = null;

  selected:any[] = []
  isUncateNull: boolean = false;

  async ngOnInit() {
    this.isUncateNull = await this.checkForUncate();
    this.selected = this._model.tags || []
    this.productTag.forEach(data => {
      data.active = false;
      this.selected.forEach(tag => {
         if(tag == data.id){
           data.active = true;
         }
       })
     });

    console.log(this.productTag)
  }

  search(){
    this.nzDrawerRef.close({
      key: 'search',
      value: ''
    })
  }

  selectCate(value:any) {
    this.nzDrawerRef.close({
      key: 'selectCate',
      value: value
    })
  }

  priceRangeChange(){
    // this.nzDrawerRef.close({
    //   key: 'filterPrice',
    //   value: this.priceRangeValue
    // })
  }

  tags(obj: any){
    if(obj.active){
      for(let i=0;i<this.selected.length;i++){
        if(this.selected[i] == obj.id){
          this.selected.splice(i,1);
        }
      }
    }else{
      this.selected.push(obj.id);
    }
    obj.active = !obj.active;
  }

  filter(){
    this.nzDrawerRef.close({
      key: 'filter',
      value: {
        priceRangeValue: this.priceRangeValue,
        tags: this.selected
      }
    })
  }

  async checkForUncate(): Promise<boolean>{
    const model = {
      view: 'grid',
      cate_id: -1,
      search: "",
      page: 1,
      perpage: 10,
      tags: [],
      sort: "3"
    }
    try {
      const uncateProducts: any = await this.Service_Product.searchProductIndex(model);
      if (uncateProducts.count) return false;
      return true;
    } catch (err) {
      console.log(err);
    }
  }

}